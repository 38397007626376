import React from "react";
import Layout from "../components/Layout";
import {graphql} from "gatsby";
import {HTMLContent} from "../components/Content";
import SimplePageTemplate from "./simple-page-template";
import useSiteMetadata from "../components/SiteMetadata";
import ReactMarkdown from "react-markdown";
import './services-page.scss';

const ServicesPage = ({data, location}) => {
    const {frontmatter, html} = data.markdownRemark
    const {title} = useSiteMetadata()

    const meta = {title: `Czynności notarialne | ${title}`}

    return (
        <Layout location={location} meta={meta}>
            <SimplePageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                subheading={frontmatter.subheading}
                contentTitle={frontmatter.contentTitle}
                contentBody={html}
                contentComponent={HTMLContent}
            />
            <section className="services-section section has-background-grey-lighter" style={{paddingTop: "0"}}>
                <div className="container">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="section has-no-padding-mobile">
                                <ReactMarkdown source={frontmatter.contentServices} className="content"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ServicesPage;

export const pageQuery = graphql`
    query ServicesPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "services-page" } }) {
            frontmatter {
                title
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                subheading
                contentTitle
                contentServices
            }
            html
        }
    }
`
