import React from 'react';
import Content from "../components/Content";
import Hero from "../components/Layout/Hero";
import ImageBackground from "../components/ImageBackground";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const SimplePageTemplate = (
    {
        image,
        title,
        subheading,
        contentTitle,
        contentBody,
        contentImage,
        contentComponent,
        contentClass
    }
) => {
    const PageContent = contentComponent || Content

    return (
        <div>
            <Hero subtitle={subheading} title={title} image={image}/>
            <section className={`section ${contentClass ? contentClass : ""}`} style={{padding: "1rem 1.5rem"}}>
                <div className="container">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="section has-no-padding-mobile">
                                <div className="columns is-multiline">
                                    <div className={`column ${contentImage ? 'is-12-tablet is-7-desktop' : 'is-12'}`} style={{paddingRight: contentImage ? '2rem' : '0.75rem'}}>
                                        <h2 className="title is-size-2 is-size-4-mobile has-text-weight-bold has-text-primary has-partial-underline has-text-uppercase">
                                            {contentTitle}
                                        </h2>
                                        <PageContent className="content" content={contentBody}/>
                                    </div>
                                    {contentImage && <div className="column is-12-tablet is-5-desktop">
                                        <div style={{display: 'flex', height: '100%', alignItems: 'center', overflow: "hidden"}}>
                                            <ImageBackground>
                                                <PreviewCompatibleImage imageInfo={contentImage}/>
                                            </ImageBackground>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SimplePageTemplate;