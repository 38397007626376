import React from 'react';
import './ImageBackground.scss';

const ImageBackground = ({children}) => {
    return (
        <div className="image-background">
            <div className="image-background__box-1"/>
            <div className="image-background__box-2"/>
            {children}
        </div>
    );
};

export default ImageBackground;